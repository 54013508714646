(function() {
    var loadTeasers = function () {

        var teaserFlag = false;

        $('._js_teasersMore').on('click', function(){

            if (!teaserFlag && $('._jsTeaserToLoad').last().data('more') !== undefined) {
                $.ajax({
                    url: $('[data-more]').last().data('more'),
                    beforeSend: function () {
                        teaserFlag = true;
                    },
                    error: function(){
                    },
                    success: function (html) {

                        $(html)
                            .insertAfter($('[data-more]').last());

                        teaserFlag = false;

                        if (!$('._jsTeaserToLoad').last().data('more')) {
                            $('._js_teasersMore').hide();
                        }
                    }

                });
            }
        });
    };
    if ($('._jsTeaserToLoad').length ) {
        loadTeasers();
    }

})();
