$(".player").each(function (i, e) {
    $(this).attr("id", "player_" + i);
    var url = $(this).attr("data-url");
    var playerInitialized = false;
    var statsQuadrantsPos = {};
    var clipName;
    var clipMid;
    var player = new WP.player({
        url: url,
        target: "player_" + i,
        adv: false,
        hiderelated: true,
        extendedrelated: false,
        floatingplayer: false,
        autoplay: true,
        forceliteembed: false,
    });
    player.on("INIT", function () {
        if (!playerInitialized) {
            playerInitialized = true;
            this.setVolume(0);
        }
    });
    player.on("START_MOVIE", function () {
        clipName = player.getClipData().title;
        clipMid = player.getClipData().mid;
        $(".section--head--button").hide();
        sendStats("video", "view", clipName);
        // console.log("video", "view", clipName);
        this.setVolume(1);
    });
    player.on("END_MOVIE", function () {
        $(".section--head--button").show();
        sendStats("video", "end", clipName);
        // console.log("video", "end", clipName);
    });
    player.on("UPDATE_POSITION", function (e) {
        var position = player.getPosition();
        statsQuadrants(position);
    });
    var statsQuadrants = function (p) {
        p = Math.round(p * 100);
        if (!statsQuadrantsPos[clipMid]) {
            statsQuadrantsPos[clipMid] = {};
        }
        if (p >= 25 && p < 50) {
            if (!statsQuadrantsPos[clipMid][25]) {
                statsQuadrantsPos[clipMid][25] = 1;
                sendStats("video", "quarter=25", clipName);
                // console.log("video", "quarter=25", clipName);
            }
        } else if (p >= 50 && p < 75) {
            if (!statsQuadrantsPos[clipMid][50]) {
                statsQuadrantsPos[clipMid][50] = 1;
                sendStats("video", "quarter=50", clipName);
                // console.log("video", "quarter=50", clipName);
            }
        } else if (p >= 75 && p < 100) {
            if (!statsQuadrantsPos[clipMid][75]) {
                statsQuadrantsPos[clipMid][75] = 1;
                sendStats("video", "quarter=75", clipName);
                // console.log("video", "quarter=75", clipName);
            }
        }
    };
});