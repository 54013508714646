'use strict';

$.extend($.easing, {
	easeOutExpo: function (x, t, b, c, d) {
		return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
	},
	easeInOutExpo: function (x, t, b, c, d) {
		if (t==0) return b;
		if (t==d) return b+c;
		if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
		return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
	}
});

//  zainicjowanie owli

var owl1item = $(".owl-1-item");
var owlProduct = $(".owl-product");

owl1item.owlCarousel({
	loop: true,
	margin: 0,
	nav: true,
	center: true,
	responsive: { 0: { items: 1 }}
});

owlProduct.owlCarousel({
	items: 1,
	loop: true,
	margin: 0,
	center: true,
	autoplay: true,
	autoplayTimeout: 8000,
	autoplayHoverPause: true,
});

// owl.trigger('stop.owl.autoplay')

//Obiekt zbierający informacje o elementach typu banner, których statystyki należy wysłać w unload
var statElementCollector = new Object();

var ga360statElementsInit = function(el) {
	alert( $(el).data('ga360-id') );
};

var slidePositionVieved = new Object();
//owlstats ga360 slider stats slider

var owl = $(".owl-carousel:not(.owl-product)").each(function(){
	//sprawdzamy, czy jest ustawiony id dla statystyk
	var $artID = $(this).parents('.__js_stats_wrapper').data('id');
	var ga360id = $(this).data('ga360-id');
	if (ga360id === undefined) {
		console.log('%c dodać ga360-id do slidera #' + $(this).data('id'), 'background: #553311; padding: 3px; color: #ffaaaa');
	} else {
		statElementCollector[ga360id + '-' + $artID] = {
			id: ga360id,
			moduleName: $(this).data('id'),
			viewedCount: 0,
			itemCount:  $(this).find('.owl-item:not(.cloned)').length
		};
	}
	$(this).statVievedSlide = [];
	//pozycje
	$(this).find('.owl-item:not(.cloned)').each(function(n){
		$(this).attr('idx',  n + 1);
	});
	slidePositionVieved[ga360id + '-' + $artID] = {};
});


function ga360Test(){

	var statElementArray = [];

	for (var pos in statElementCollector) {
		statElementArray.push(statElementCollector[pos]);
	}
	// console.log(statElementArray);
}

owl.find('.owl-next, .owl-prev').on('click', function(event) {
	var $owl = $(this).parents('.owl-carousel');
	var $artID = $(this).parents('.__js_stats_wrapper').data('id');
	var $gId = $(this).parents('.owl-carousel').data('id');
	// var $acId = $owl.find('.owl-item.active').eq(0).find('.owl-img>img').attr('src');
	var $acId = $owl.find('.owl-item.center .owl-img>img').attr('src');
	//ga360
	var ga360id = $owl.data('ga360-id');
	var activeItem = $owl.find('.owl-item.center').attr('idx');
	slidePositionVieved[ ga360id + '-' + $artID ][ activeItem ] = 1;
	statElementCollector[ ga360id + '-' + $artID ]['viewedCount'] = Object.keys(slidePositionVieved[ ga360id + '-' + $artID ]).length;

	sendStats("gallery", "click", $artID + " / " + $gId, $acId);
	WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory":ga360id, "moduleName": $gId ,"eventAction": "click"}});
});


owl.on("dragged.owl.carousel", function(event) {
	var $artID = $(this).parents('.section-wrapper').data('id');
	var $gId = $(this).data('id');
	var ga360id = $(this).data('ga360-id');
	var $item = $('.owl-item');
	var $img = $item.find('img');
	var $acId = $img.eq(event.item.index).attr('src');
	sendStats("gallery", "swipe", "strona-glowna-sekcja-" + $artID + " / " + $gId, $acId);
	WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory":ga360id, "moduleName": $gId,"eventAction": "swipe"}});
});

var viewData = [];


// flaga dla urządzeń z touch:
if ('ontouchstart' in document.documentElement) {
	var deviceTouch = true;
}

// flaga dla scrollowania:
var isScrolligFlag = false;
var idTS;

window.addEventListener('scroll', function(){
	isScrolligFlag = true;
	if (idTS) {
		clearTimeout(idTS);
	}
	idTS = setTimeout(function(){
		idTS = null;
		isScrolligFlag = false;
	}, 450);
});

$('[data-st-click]').bind('click', function(){
	var T = $(this).data("st-click");
	if (T.length) {
		if(T.value.length) {
			sendStats(T.category, T.action, T.label, T.value);
		}
		else {
			sendStats(T.category, T.action, T.label);
		}
	}
});
