(function() {
    $('.__js_socialShare').click(function(e){

        e.preventDefault();

        var categoryName = $(this).data('category'),
            href = $(this).data('href'),
            $aID = $(this).parents('.__js_stats_wrapper').data('id'),
            ga360Socials;


        if($aID  == null) { $aID = 'footer';}

        sendStats('share-' + categoryName, 'click', $aID);

        if (categoryName === 'facebook') {
            window.open(href, '_blank', 'top=100, left=300, width=320,height=320,toolbar=1,resizable=0'); ga360Socials = 'share_FB';
        };
        if (categoryName === 'twitter') {
            window.open(href, '_blank', 'top=100, left=300, width=320,height=320,toolbar=1,resizable=0'); ga360Socials = 'share_Twitter';
        };

        WP.pwp.customAction({
            "ga": {
                "event": "site_interaction",
                "eventCategory": ga360Socials,
                "eventAction": "click"
            }
        });

    });
})();
